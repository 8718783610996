@font-face {
  font-family: 'Sohne';
  src: local('Sohne'), url(./soehne-halbfett.woff2) format('woff2');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Anthro';
  src: local('Anthro'), url(./AnthroVariableGX.ttf) format('truetype');
  font-weight: 300 700;
  /* other formats include: 'woff2', 'truetype, 'opentype',
                                'embedded-opentype', and 'svg' */
}
